<template>
  <div class="vx-col w-full">
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex-grow">
          <vx-card title="">
          <!-- COMPUTED STORE CHECK USE BANK  DO NOT DELETE BY LILSHARK-->
          <div class="vx-card__title">
            <h4>เลือกวันที่</h4>
            <br />
          </div>
          <flat-pickr v-model="date" />
          <p class="mt-4">Selected Date: {{ date }}</p>
        </vx-card>
        </div>
        
        <br />
        <vx-card>
          <vs-table
            stripe
            max-items="20"
            pagination
            :data="dataCallcenter"
            search
          >
            <template slot="thead">
              <vs-th>วันเวลาทีโทร</vs-th>
              <vs-th>วันเวลาที่สมัคร</vs-th>
              <vs-th>ยูสเซอร์เนม</vs-th>
              <vs-th>เบอร์โทรศัพท์</vs-th>
              <vs-th>ชื่อ-นามสกุล</vs-th>
              <vs-th>Line</vs-th>
              <vs-th>สถานะ</vs-th>
              <vs-th>info</vs-th>
              <vs-th>เติมเงิน</vs-th>
              <vs-th>คนโทร</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.member_register_date">
                  {{
                    moment(tr.confrim_date).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}
                </vs-td>
                <vs-td :data="tr.member_register_date">
                  {{
                    moment(tr.register_date).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}
                </vs-td>

                <vs-td class="text-danger"
                  ><router-link
                    :to="`/member/${data[indextr].username}`"
                    >{{ data[indextr].username }}</router-link
                  ></vs-td
                >

                <vs-td :data="tr.phone">
                  {{ tr.phone }}
                </vs-td>
                <vs-td :data="tr.fullname">
                  {{ tr.fullname }} 
                </vs-td>
                <vs-td :data="tr.line">
                  {{ tr.line }}
                </vs-td>
                <vs-td  :class="data[indextr].status == 1?'text-success':data[indextr].status == 2 || data[indextr].status == 3 ?'text-warning':data[indextr].status == 4?'text-danger':null">
           {{check_status(data[indextr].status)}} 
            </vs-td>
                <vs-td :data="tr.info">
                  {{ tr.info }}
                </vs-td>
                <vs-td :data="tr.member_topup">
                  <div v-if="tr.member_topup==='0.00'" class="text-danger">
                  ยังไม่เติมเงิน</div>
                  <div v-else class="text-success">
                  เติมเงินแล้ว</div>
                </vs-td>
                <vs-td :data="tr.confrim_by">
                  {{ tr.confrim_by }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  data () {
    return {
      admin_user: this.$store.state.AppActiveUser,
      date: moment.tz('Asia/Bangkok').format('YYYY-MM-DD'),

      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true
      },
      dataCallcenter:[],
      username:'',
      registerDate:'',
      status_usebank:'',
      popup_confrim: false,
      info:'',
      member_line:'',
      member_phone:'',
      member_name:'',
      member_surname:'',
      member_username:'',
      status:''


    }
  },
  components: {
    flatPickr,
    StatisticsCardLine,
    vSelect
  },
  async mounted () {
    await this.selectdate()
  },
  watch: {
    date (newValue) {
      this.date = newValue
      this.selectdate()
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async selectdate () {
      await axios
        .get(`callcenter/transaction/${this.date}`)
        .then(response => (this.dataCallcenter = response.data))
    },
    check_status (status) {
      if (status === '0') {
        return 'กำลังรอทำรายการ'
      } else if (status === '1') {
        return 'โทรสำเร็จ'
      } else if (status === '2') {
        return 'ติดปัญหา'
      }
    },
    // eslint-disable-next-line no-unused-vars
    async confirm (member_phone,      
      member_name,
      member_surname,
      member_line,
      member_register_date,
      member_username) {

      await axios
        .post('callcenter/confirm', {
          member_phone,
          member_name,
          member_surname,
          member_line,
          member_register_date,
          member_username
        }).then(response => (this.status_confirm = response.data))
      if (this.status_confirm.status === true) {
        await this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          title: this.status_confirm.info.title,
          text: this.status_confirm.info.info
        })
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: this.agent_error.info.title,
          text: this.agent_error.info.info
        })
      }
      await this.selectdate_time(this.before_datetime, this.after_dateitme)
    },
    async warningConfirm (
      member_phone,      
      member_name,
      member_surname,
      member_line,
      member_register_date,
      member_username,
      info)  {
      await axios
        .post('callcenter/confirm', {
          member_phone,
          member_name,
          member_surname,
          member_line,
          member_register_date,
          member_username,
          info
        }).then(response => (this.status_confirm = response.data))
      if (this.status_confirm.status === true) {
        await this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          title: this.status_confirm.info.title,
          text: this.status_confirm.info.info
        })

      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: this.agent_error.info.title,
          text: this.agent_error.info.info
        })
      }
      this.popup_confrim = false
      await this.selectdate_time(this.before_datetime, this.after_dateitme)
    },
    async noConfirm (member_phone, member_name, member_surname, member_line, member_register_date, member_username) {
      this.member_phone = member_phone
      this.member_name = member_name
      this.member_surname = member_surname
      this.member_line = member_line
      this.member_register_date = member_register_date
      this.member_username = member_username
      this.popup_confrim = true
      this.$nextTick(() => {
        this.$refs.inputPassword.$el.querySelector('input').focus()
      })
    }
  }
}
</script>

<style></style>
